<template>
  <div class="new-clients-container">
    <admin-header-vue alias="" pageName="New Product" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column">
            <div class="display-flex">
              <upload-vue
                label="Light Logo"
                :submitted="submitted"
                :image="newProduct.properties.logo_light"
                @onChangeImage="onChangeLightLogo" />
              <upload-vue
                label="Dark Logo"
                :submitted="submitted"
                :image="newProduct.properties.logo_dark"
                @onChangeImage="onChangeDarkLogo" />
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Product Name" />
          </div>
          <div class="column is-4 form-group relative">
            <p>Company</p>
            <v-select
              label="companyName"
              placeholder="Type to search.."
              :options="localCompanyList"
              :loading="loadingSearchedCompanies"
              @search="handleCompanySearch"
              :clearSearchOnSelect="true"
              :value="selectedCompany"
              @input="onChangeProductCompany"
              :filterable="false">
              <template slot="no-options">
                Type to search..
            </template></v-select>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <input-vue
              :required="false"
              type="textarea"
              :submitted="submitted"
              :max="500"
              @onChangeName="onChangeDescription"
              label="Description" />
          </div>
          <div class="column alias-area is-6">
            <input-vue
              :required="false"
              :value="aliasValue"
              type="textarea"
              :submitted="submitted"
              @onChangeName="onChangeAlias"
              label="Alias (one per line)" />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Add</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import UploadVue from '../../components/Upload.vue'
import { debounce } from '../../../../util/util'
import Snackbar from '../../../../components/Snackbar'

/** This component contains the code to add a new product to the database */
export default {
  name: 'AddProduct',
  components: {
    AdminHeaderVue,
    InputVue,
    UploadVue
  },
  data () {
    return {
      newProduct: {
        name: null,
        properties: {
          logo_light: null,
          logo_dark: null
        },
        lightLogoName: null,
        darkLogoName: null,
        description: null,
        company_id: null,
        aliasArray: null
      },
      localCompanyList: [],
      loadingSearchedCompanies: false,
      submitted: false,
      isLightLogoInvalid: false,
      isDarkLogoInvalid: false,
      isProductNameInvalid: true,
      isProductDescriptionInvalid: false,
      isAliasValid: true,
      aliasValue: '',
      selectedCompany: {
        companyId: null,
        companyName: null
      }
    }
  },
  computed: {
    ...mapState('company', ['companyList'])
  },
  watch: {},
  mounted () {
  },
  methods: {
    ...mapActions('products', ['addProduct']),
    ...mapActions('company', ['getCompanyList']),
    /** This method stores the file url and its name for the light theme of the site
     * @public
    */

    handleCompanySearch (data) {
      if (data) {
        this.loadingSearchedCompanies = true
      }
      this.debounceCompanySearch(data)
    },

    debounceCompanySearch: debounce(async function (data) {
      if (data.length) {
        this.localCompanyList = await this.getCompanyList(data)
      }
      this.loadingSearchedCompanies = false
    }, 800),

    onChangeProductCompany (company) {
      if (company) {
        this.selectedCompany = company
        this.newProduct.company_id = company.companyId
      } else {
        this.selectedCompany = {
          companyId: null,
          companyName: null
        }
      }
    },

    onChangeLightLogo ({ file, name }, isInvalid) {
      this.newProduct.properties.logo_light = file
      this.newProduct.lightLogoName = name
      this.isLightLogoInvalid = isInvalid
    },
    /** This method stores the file url and its name for the dark theme of the site
     * @public
    */
    onChangeDarkLogo ({ file, name }, isInvalid) {
      this.newProduct.properties.logo_dark = file
      this.newProduct.darkLogoName = name
      this.isDarkLogoInvalid = isInvalid
    },
    /** This method defines the behavior for when the product name (Input value) is changed
     * @param name {String} - The name of the new product
     * @param isInvalid {Boolean}
     * @public
     */
    onChangeName (name, isInvalid) {
      this.newProduct.name = name
      this.isProductNameInvalid = isInvalid
    },
    onChangeAlias (value, isInvalid) {
      value = value || ''
      this.newProduct.aliasArray = value.split('\n').filter(a => a.trim() !== '')
    },
    onChangeDescription (description, isInvalid) {
      this.newProduct.description = description
      this.isProductDescriptionInvalid = isInvalid
    },
    onChangeCompany (company, isInvalid) {
      this.newProduct.company_id = company.id
    },
    /** This method validates the input and submits the form
     * @public
     */
    handleSubmit (e) {
      this.submitted = true
      if (
        !(
          this.isLightLogoInvalid ||
          this.isDarkLogoInvalid ||
          this.isProductNameInvalid ||
          this.isProductDescriptionInvalid ||
          (!this.isAliasValid)
        )
      ) {
        this.addProduct(this.newProduct)
        this.aliasValue = this.newProduct.aliasArray.join('\n')
      } else {
        Snackbar({ message: 'Please check if all the inputs are valid.', type: 'is-danger' })
      }
    },
    /** This method redirects the user back to the product page
     * @public
     */
    handleCancel () {
      this.$router.push('/admin/product')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../Companies/vue-select-custom.scss";

.new-clients-container {
    margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .column {
        .display-flex {
          display: flex;
        }
        .display-flex div:nth-child(2) {
          padding-left: 20px;
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

::v-deep .form-group {
  .textarea:focus, .textarea:hover {
    border: var(--tertiary-border-color);
  }
}

::v-deep .alias-area {
  textarea {
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
  }
}
</style>
